<template>
  <div class="d-flex">
    <div
      class="slot"
      @dblclick="initModal"
    >
      <slot />
    </div>

    <b-modal
      v-if="localData"
      id="editProductModal"
      v-model="modalShow"
      centered
      size="lg"
      :title="`${localData.name} - ${localData.sku}`"
      tabindex="0"
      @keyup.enter="onSubmit(field)"
    >
      <div v-if="field === 'price'">
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('form.currency.label')"
              label-for="currency_code"
              :state="errors && errors.currency_code ? false : null"
            >
              <v-select
                id="currency_code"
                v-model="localData.currency_code"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="currencies"
                :reduce="val => val.value"
                :clearable="false"
                input-id="currency_code"
              />
              <b-form-invalid-feedback v-if="errors && errors.currency_code">
                {{ errors.currency_code[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-for="(price, index) in localData.prices"
          :id="'price' + index"
          :key="index"
        >
          <b-col
            cols="12"
            md="2"
          >
            <br>
            {{ $t('form.price.label') }} для {{ price.role.title }}
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <MultiPrice
              v-model="price.price"
              :index="index"
              :label="$t('form.price.label')"
              :type="'prices'"
              :currency="localData.currency_code"
            />
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <MultiPrice
              v-model="price.old_price"
              :index="index"
              :label="$t('form.old_price.label')"
              :type="'prices'"
              :currency="localData.currency_code"
            />
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row
          v-for="(price, index) in localData.discounts"
          :id="'discounts' + index"
          :key="'discounts' + index"
        >
          <b-col
            cols="12"
            md="2"
          >
            <br>
            {{ $t('admin.table.fields.sale_price') }} для {{ price.role.title }}
          </b-col>
          <b-col
            cols="12"
            md="10"
          >
            <MultiPrice
              v-model="price.price"
              :index="index"
              :label="$t('form.price.label')"
              :type="'discounts'"
              :currency="localData.currency_code"
            />
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="field === 'stocks'">
        <b-row
          v-for="(stock, index) in localData.stocks"
          :id="'stock' + index"
          :key="index"
          ref="row"
        >
          <b-col
            cols="12"
            md="4"
          >
            <br>
            {{ $t('admin.table.fields.quantity') }} на {{ stock.title }}
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('form.quantity.label')"
              :label-for="'stocks.' + index + '.quantity'"
            >
              <b-form-input
                :id="'stocks.' + index + '.quantity'"
                v-model="stock.quantity"
                type="number"
                step="1"
                min="1"
                max="999999"
                :state="errors && errors['stocks.' + index + '.quantity'] ? false : null"
              />

              <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.quantity']">
                {{ errors['stocks.' + index + '.quantity'][0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="field === 'sku'">
        <b-form-group
          :label="$t('form.sku.label')"
          label-for="sku"
        >
          <b-form-input
            id="sku"
            v-model="localData.sku"
            :state="errors && errors.sku ? false : null"
          />

          <b-form-invalid-feedback v-if="errors && errors.sku">
            {{ errors.sku[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          :label="$t('form.tag.label')"
          label-for="tag"
          class="mt-2"
        >
          <b-form-input
            id="tag"
            v-model="localData.tag"
            :state="errors && errors.tag ? false : null"
          />

          <b-form-invalid-feedback v-if="errors && errors.tag">
            {{ errors.tag[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div v-else-if="field === 'position'">
        <b-form-group
          :label="$t('form.position.label')"
          label-for="position"
        >
          <b-form-input
            id="position"
            v-model="localData.position"
            type="number"
            :state="errors && errors.position ? false : null"
          />

          <b-form-invalid-feedback v-if="errors && errors.position">
            {{ errors.position[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div v-else-if="field === 'stock_status'">
        <b-form-group
          :label="$t('form.stock_status.label')"
          label-for="stock_status_id"
          :state="errors && errors.stock_status_id ? false : null"
        >
          <v-select
            id="stock_status_id"
            v-model="localData.stock_status_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statuses"
            :reduce="val => val.value"
            :clearable="false"
            input-id="stock_status_id"
          />
          <b-form-invalid-feedback v-if="errors && errors.stock_status_id">
            {{ errors.stock_status_id[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div v-else-if="field === 'status'">
        <b-form-group
          :label="$t('form.status.label')"
          label-for="status"
          :state="errors && errors.status ? false : null"
        >
          <v-select
            id="status"
            v-model="localData.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            :reduce="val => val.value"
            :clearable="false"
            input-id="status"
          />
          <b-form-invalid-feedback v-if="errors && errors.status">
            {{ errors.status[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div v-else-if="field === 'avatar'">
        <b-form-group :label="$t('form.avatar.label')">
          <div class="mb-75">
            <InitFileManager
              field="avatar"
              type="product_avatar"
              :multiple="false"
              @fm-selected="selectedAvatar"
            />
          </div>
          <!-- media -->
          <div class="media">
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  :src="$options.filters.mediaUrl(localData, 'avatar', '150x150')"
                  height="150"
                />
              </b-link>
              <!--/ avatar -->

              <!-- reset -->
              <b-button
                v-if="localData.avatar"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
                @click="localData.avatar = null"
              >
                {{ $t('general.reset') }}
              </b-button>
              <!--/ reset -->
            </b-media-aside>
          </div>
          <!--/ media -->
          <div class="mt-75">
            <b-row
              v-if="errors && errors.avatar"
            >
              <b-col cols="12">
                <b-form-invalid-feedback :state="false">
                  {{ errors.avatar[0] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row
              v-if="errors && errors['avatar.type']"
            >
              <b-col cols="12">
                <b-form-invalid-feedback :state="false">
                  {{ errors['avatar.type'][0] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </div>
        </b-form-group>
      </div>
      <div v-else-if="field === 'title'">
        <b-tabs>
          <b-tab
            v-for="language in languages"
            :key="language.code"
          >
            <template #title>
              <b-img
                :src="require('@/assets/images/flags/' + language.code + '.svg')"
                height="16"
                width="16"
                class="mr-1"
              />
              <span class="d-none d-sm-inline">{{ language.title }}</span>
            </template>
            <b-row>
              <b-col
                cols="24"
                md="12"
              >
                <b-form-group
                  :label="$t('form.title.label')"
                  :label-for="'title.' + language.code"
                >
                  <b-form-input
                    :id="'title.' + language.code"
                    v-model="localData.title[language.code]"
                    :state="errors && errors['title.' + language.code] ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                    {{ errors['title.' + language.code][0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
      <div v-else-if="field === 'categories'">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              :label="$t('form.categories.label')"
              label-for="categories"
              :state="errors && errors.categories ? false : null"
            >
              <v-select
                id="categories"
                v-model="localData.categories"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                :reduce="val => val.value"
                :clearable="true"
                :multiple="true"
                input-id="categories"
              />
              <b-form-invalid-feedback v-if="errors && errors.categories">
                {{ errors.categories[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              :label="$t('form.top_category.label')"
              label-for="categories"
              :state="errors && errors.category_id ? false : null"
            >
              <v-select
                id="category_id"
                v-model="localData.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoryOptionsTop"
                :reduce="val => val.value"
                :clearable="true"
                :multiple="false"
                input-id="category_id"
              />
              <b-form-invalid-feedback v-if="errors && errors.category_id">
                {{ errors.category_id[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        {{ field }}
      </div>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-2"
          variant="primary"
          block
          @click="onSubmit(field)"
        >
          {{ $t('general.save') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import MultiPrice from '@/components/MultiPrice.vue'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    MultiPrice,
  },
  mixins: [GlobalMixin],
  props: {
    field: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    currencies: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalShow: false,
      localData: null,
    }
  },
  computed: {
    categoryOptionsTop() {
      return _.filter(this.categories, cat => _.includes(this.localData.categories, cat.value))
    },
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyUp)
  },
  methods: {
    initModal() {
      this.localData = this.transformData(this.product)
      this.modalShow = true
    },
    hideModal() {
      this.modalShow = false
      this.localData = null
    },
    selectedAvatar(data) {
      this.localData.avatar = data
    },
    transformData(item) {
      const data = {
        ...item,
        name: item.title,
        avatar: this.$options.filters.singleMedia(item.avatar),
        stocks: _.map(item.stocks, s => ({
          title: s.title,
          stock_id: s.id,
          quantity: s.quantity,
        })),
        categories: _.map(item.categories, 'id'),
        title: {},
      }
      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })
        data.title[language.code] = translation ? translation.title : null
      })

      return data
    },
    onSubmit(field) {
      if (!field) return

      this.$http.put(`/api/admin/products/${this.localData.id}/update/${field}`, {
        title: this.localData.title,
        currency_code: this.localData.currency_code,
        prices: this.localData.prices,
        discounts: this.localData.discounts,
        stocks: this.localData.stocks,
        avatar: this.localData.avatar,
        status: this.localData.status,
        sku: this.localData.sku,
        tag: this.localData.tag,
        position: this.localData.position,
        stock_status_id: this.localData.stock_status_id,
        category_id: this.localData.category_id,
        categories: this.localData.categories,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Збережено',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('refresh')
          this.hideModal()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    handleKeyUp(event) {
      if (event.key === 'Enter' && this.localData && this.modalShow) {
        this.onSubmit(this.field)
      }
    },
  },
}
</script>
